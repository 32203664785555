/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Text, Image, YouTube, Button, Fullmap, FullmapWrap, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Vánoce"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1ji4lbi --center pb--80 pt--80" name={"uvod"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/e7004ec9d12745c4a97b30d0277fbbbd_bri=115_sat=0__s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--26" content={"<span style=\"font-weight: bold; color: var(--white);\">Děkujeme všem, co se stali Ježíškem dětem z dětských domovů.<br></span>"}>
              </Title>

              <Title className="title-box title-box--left fs--62" content={"<span style=\"color: var(--color-dominant);\"><br><br><br><br></span><br><br><br><br><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kontakt-0"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(255, 255, 255, 1)"}}>
        </Column>


        <Column className="--center" name={"5jphiqch6q"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="css-vuvwpg pb--60 pt--60" name={"9piz7o7dzcn"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/5ea545042a764e7db1ae9812a436a3e0_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/5ea545042a764e7db1ae9812a436a3e0_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/5ea545042a764e7db1ae9812a436a3e0_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/5ea545042a764e7db1ae9812a436a3e0_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/5ea545042a764e7db1ae9812a436a3e0_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/5ea545042a764e7db1ae9812a436a3e0_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/5ea545042a764e7db1ae9812a436a3e0_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/5ea545042a764e7db1ae9812a436a3e0_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--center fs--26" content={"<span style=\"font-weight: bold;\">Vážení přátelé, dámy a pánové, milí dárci,</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Vánoce jsou za námi. Ne všichni ale mají možnost tyto nejkrásnější svátky v roce trávit doma s rodinou. Každoročně zůstávají v dětských ústavních zařízeních tisíce dětí a já osobně jsem byl před pár lety jedním z nich. A právě proto jsem se rozhodl dělat každé vánoce těmto dětem o něco příjemnější.</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--center fs--26" content={"<span style=\"font-weight: bold;\">Jsme si vědomi, že bez Vaší podpory by to opravdu nešlo!\n\n</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">A proto nám dovolte Vám ze srdce osobně i za všechny „naše“ děti poděkovat. Vážíme si toho, že Vám nejsou lhostejné osudy dětí, které zatím neměly v životě tolik štěstí.&nbsp; &nbsp;</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--center fs--26" content={"<span style=\"font-weight: bold;\">Ježíškova tour po dětských domovech běžela celý prosinec! \n\n</span>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Ježíškova tour po dětských domovech je naší dlouhodobou a úspěšnou tradicí, během které dochází k plnění dětských přání. Začínáme s ní vždy u nás v Pardubickém kraji a končíme ji na Vysočině v podvečer před Štědrým dnem. Ježíškova tour je čas, ve kterém věnujeme pozornost dětem, tetám a strejdům, které o děti denně pečují, děkujeme jim a snažíme se jim být oporou a věnujeme jim to nejcennější – náš čas.\n\n</span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--center fs--26" content={"<span style=\"font-weight: bold;\">Z Pardubic do Černé Vody přes Litovel, z Prahy do Tachova <br>nebo z Kadaně do Krásné Lípy přes Střekov.&nbsp; &nbsp;</span><br>"}>
              </Subtitle>

              <Text className="text-box text-box--justify" style={{"maxWidth":""}} content={"<span style=\"color: var(--black);\">Díky Vaší podpoře jsme mohli býti Ježíškem pro cca 1000 dětí z celé České republiky. Jedná se například o tyto dětské domovy: dětský domov Černá Voda , dětský domov Litovel, dětský domov  Ústí nad Labem, Střekov, dětský domov Krásná Lípa, dětský domov Sedloňov, dětský domov Mladá Boleslav, dětský domov Tachov, dětský diagnostický ústav Hradec Králové, dětský domov Nová Ves u Chotěboře nebo dětský domov Vrbno pod Pradědem a další…&nbsp; &nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"4aj613jtwxd"} layout={"l29"}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/8a41cd89e5c14295ba346bc20acc8137_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/8a41cd89e5c14295ba346bc20acc8137_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/8a41cd89e5c14295ba346bc20acc8137_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/8a41cd89e5c14295ba346bc20acc8137_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/8a41cd89e5c14295ba346bc20acc8137_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/8a41cd89e5c14295ba346bc20acc8137_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/8e5598b579c848c0bf6fe35c8ea15525_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/8e5598b579c848c0bf6fe35c8ea15525_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/8e5598b579c848c0bf6fe35c8ea15525_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/8e5598b579c848c0bf6fe35c8ea15525_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/8e5598b579c848c0bf6fe35c8ea15525_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/ab2c3441d3ae4c559901223cce5756e3_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/ab2c3441d3ae4c559901223cce5756e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/ab2c3441d3ae4c559901223cce5756e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/ab2c3441d3ae4c559901223cce5756e3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/ab2c3441d3ae4c559901223cce5756e3_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17142/d77fe3c741cc4a84ab222014b5ac23a0_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/17142/d77fe3c741cc4a84ab222014b5ac23a0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/17142/d77fe3c741cc4a84ab222014b5ac23a0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/17142/d77fe3c741cc4a84ab222014b5ac23a0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/17142/d77fe3c741cc4a84ab222014b5ac23a0_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/17142/d77fe3c741cc4a84ab222014b5ac23a0_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"c5adz5w30dq"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"} fullscreen={false}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold; color: rgb(242, 6, 6);\">Ježíšek v DD Krásná Lípa</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--black); font-weight: bold;\">Video: Mgr. Jan Klepárník<br>&nbsp;(organizace Děti v akci)&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <YouTube embedId={"5NBtOXc2osM"}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1t8j3a9 css-42e4bw pb--80 pt--80" name={"ifcwoxmpjm"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/17142/a7df5fdf54c844d3af552f98a2633515_con=110_ove=000000x25__s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--30" content={"<span style=\"font-weight: bold; color: var(--color-blend--95);\">Vánoce pro dětské domovy <br>v České televizi.&nbsp;</span>"}>
              </Title>

              <Button className="btn-box btn-box--pbtn3 btn-box--shape5 btn-box--filling2 btn-box--left" url={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/223411010101109/cast/1010348/"} href={"https://www.ceskatelevize.cz/porady/1096902795-studio-6/223411010101109/cast/1010348/"} content={"<span style=\"font-weight: bold; color: var(--color-blend--95);\">reportáž zde</span>"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" name={"xohgb9jkxib"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <SeparateLine className="pb--10 pt--10" name={"7qp1wa3hfmi"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>

      </Layout>
    </ThemeWrapper>
  )
}